<!-- nav -->
<mat-toolbar *ngIf="loginDisplay">
    <div>
        <a mat-button routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a>
        <a mat-button routerLink="/customers" routerLinkActive="active">Customers</a>
        <a *ngIf="superadmin"  mat-button routerLink="/users" routerLinkActive="active">Users</a>
        <!--button class="btn btn-link nav-item nav-link" (click)="logout()">Logout</button-->
    </div>
</mat-toolbar>

<!-- main app container -->
<mat-card>
  <mat-card-content>
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </mat-card-content>
</mat-card>
