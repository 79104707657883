import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import { NgIf } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import {MatCardModule} from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AuthenticationResult, EventMessage, EventType, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { filter } from 'rxjs/operators';

import { UserService } from "./_services/user-backend";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [MatSnackBarModule, MatCardModule, MatToolbarModule, MatButtonModule, MatIconModule, NgIf, RouterOutlet, RouterLink, RouterLinkActive],
})
export class AppComponent  implements OnInit {
  loginDisplay = false;
  isIframe = false;
  title = 'user-customer-admin';
  superadmin = false;

  constructor(
      @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
      private authService: MsalService,
      private msalBroadcastService: MsalBroadcastService,
      private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.isCurrentUserSuperAdmin()
      .subscribe(superadmin => this.superadmin = superadmin);

    this.isIframe = window !== window.parent && !window.opener;
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
            this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
        } else {
            this.authService.loginPopup()
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
        }
    } else {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }
  }
}
