import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../environments/environment";

export interface CustomerPermission {
  id: number;
  permission: number;
}

export interface User {
	id: string;
	name: string;
  userPrincipalName: string;
	eMail: string;
	phone: string;
  products: string[];
  customers: CustomerPermission[];

	isDeleting: boolean;
}

export interface Users {
  users: User[];
  nextPageLink: string;
}

@Injectable({
	providedIn: "root",
})
export class UserService {
	url = environment.endpoint;

	constructor(private http: HttpClient) {}

	isCurrentUserSuperAdmin() {
		return this.http.get<boolean>(this.url + "users/iscurrentusersuperadmin");
	}

	getUsersPagedGroup(customerId: number, searchText: string, pageSize: number, nextPageLink: string ) {
		return this.http.get<Users>(this.url + "users?customerId=" +customerId +  "&name=" +encodeURIComponent(searchText) + "&nextPageLink=" + encodeURIComponent(nextPageLink) + "&pageSize=" + pageSize);
	}

	getUsersPaged(searchText: string, pageSize: number, nextPageLink: string ) {
		return this.http.get<Users>(this.url + "users?name=" +encodeURIComponent(searchText) + "&nextPageLink=" + encodeURIComponent(nextPageLink) + "&pageSize=" + pageSize);
	}

	getUsers(searchText: string) {
		return this.http.get<Users>(this.url + "users?name=" +encodeURIComponent(searchText));
	}

  getUserById(id: string) {
		return this.http.get<User>(this.url + "users/" + id);
  }

  updateUser(id: string, value: User) {
		return this.http.put<User>(this.url + "users/" + id, value);
  }

  addUser(value: User) {
		return this.http.post<User>(this.url + "users", value);
  }

  deleteUser(id: string) {
		return this.http.delete<User>(this.url + "users/" + id);
  }
}
