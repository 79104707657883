import { Routes } from "@angular/router";

import { HomeComponent } from './home/home.component';
import { MsalGuard } from '@azure/msal-angular';

const customersModule = () => import('./customers/customers.routes').then(x => x.CUSTOMERS_ROUTES);
const usersModule = () => import('./users/users.routes').then(x => x.USERS_ROUTES);

export const APP_ROUTES: Routes = [
    { path: '', component: HomeComponent, canActivate: [MsalGuard] },
    { path: 'customers', loadChildren: customersModule, canActivate: [MsalGuard] },
    { path: 'users', loadChildren: usersModule, canActivate: [MsalGuard] },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];
